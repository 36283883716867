import React from "react";

import Layout from "src/components/layout";
import Hero from "src/components/hero";
import Breadcrumb from "src/components/breadcrumb";

class ComponentsTables extends React.Component {
  render() {
    const pageData = {
      categories: [ {name: "Category", slug: "environment"}],
      title: 'subpage',
      acf: {
        heading_line1: 'Subpage Components',
        subheading: 'Various components used by subpages.'
      }
    }
    // 'https://source.unsplash.com/1920x443'

    return (
      <Layout hasHero={true}>
        <Hero type="short" headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2} heroBackgroundUrl={pageData.acf.hero_background} subheading={pageData.acf.subheading} />

        <Breadcrumb categories={pageData.categories} pageTitle={pageData.title} />
    
        {/* ==== START PierPASS Table ==== */}
        <div className="content">
          <hr />
          <h2>PierPASS Table (table type 3</h2>
        </div>

        <div id="visualizer-5191-1263597784" class="table--type-3 table visualizer-front visualizer-front-5191">
          <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
            <table class="dataTable visualizer-data-table table table-striped no-footer" id="DataTables_Table_0" role="grid" style={{width: '1226px'}}>
              <thead>
                <tr role="row">
                  <th class="sorting_disabled" rowspan="1" colspan="1" style={{width: '227px'}}>Terminal</th>
                  <th class="sorting_disabled" rowspan="1" colspan="1" style={{width: '142px'}}>Mon</th>
                  <th class="sorting_disabled" rowspan="1" colspan="1" style={{width: '124px'}}>Tue</th>
                  <th class="sorting_disabled" rowspan="1" colspan="1" style={{width: '140px'}}>Wed</th>
                  <th class="sorting_disabled" rowspan="1" colspan="1" style={{width: '128px'}}>Thu</th>
                  <th class="sorting_disabled" rowspan="1" colspan="1" style={{width: '104px'}}>Fri</th>
                  <th class="sorting_disabled" rowspan="1" colspan="1" style={{width: '117px'}}>Sat</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td>Pier A</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr role="row">
                  <td>C 60</td>
                  <td>O</td>
                  <td>O</td>
                  <td>X</td>
                  <td>O</td>
                  <td>O</td>
                  <td>X</td>
                </tr>
                <tr role="row">
                  <td>ITS</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr role="row">
                  <td>LBCT</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>X</td>
                  <td>O</td>
                </tr>
                <tr role="row">
                  <td>PT</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr role="row">
                  <td>TTI</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>O</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>



        <div className="grid grid--subpage gutter">
          <article className="content-wrap">

            {/* =============================== */}
            {/* ==== START PORT OPERATIONS ==== */}
            {/* =============================== */}

            {/* ==== START Port Operations Table: TEUs ==== */}
            <div className="content">
              <hr />
              <h2>Port Operations<br/>(like for Port Operations > TTI)</h2>
            </div>

            {/* PIER T BERTHS T132-T140 */}
            <div className="table-wrapper">
              <table className="table table--type-1">
                <tbody>
                  <tr>
                    <th scope="row">Terminal Operator</th>
                    <td><a href="http://www.totalterminals.com/" target="_blank" rel="noopener noreferrer">Total Terminals International</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Carriers: Rotation</th>
                    <td><a href="/">View Weekly Ocean Carrier Services</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Total Terminal Area</th>
                    <td>385 ac. | 155.8 ha.</td>
                  </tr>
                  <tr>
                    <th scope="row">Length of Berths</th>
                    <td>5,000 ft. | 1,524 m</td>
                  </tr>
                  <tr>
                    <th scope="row">Gantry Cranes</th>
                    <td>14</td>
                  </tr>
                  <tr>
                    <th scope="row">Design Depth of Water</th>
                    <td>55 ft. | 16.8 m</td>
                  </tr>
                  <tr>
                    <th scope="row">Cargoes Handled</th>
                    <td>General cargo in containers</td>
                  </tr>
                  <tr>
                    <th scope="row">Special Equipment &amp; Facilities</th>
                    <td>1850 reefer outlet capacity</td>
                  </tr>
                  <tr>
                    <th scope="row">On-Dock Rail</th>
                    <td>Intermodal rail car capacity: 174</td>
                  </tr>
                  <tr>
                    <th scope="row">Address</th>
                    <td>301 Mediterranean Way, Long Beach, CA 90802</td>
                  </tr>
                  <tr>
                    <th scope="row">Phone</th>
                    <td><a href="tel:1-562-256-2600">(562) 256-2600</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Fax</th>
                    <td>(562) 499-3588</td>
                  </tr>
                  <tr>
                    <th scope="row">Web Site</th>
                    <td><a href="https://www.ttilgb.com" target="_blank" rel="noopener noreferrer">www.ttilgb.com</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Appointment System</th>
                    <td><a href="https://www.ttilgb.com" target="_blank" rel="noopener noreferrer">www.ttilgb.com</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Map</th>
                    <td><a href="/" target="_blank" rel="noopener noreferrer">Terminal Map</a></td>
                  </tr>
                </tbody>
              </table>
            </div>

          {/* same as above but with fake content
            <div className="table-wrapper">
              <table class="table table--type-1">
                <tbody>
                  <tr>
                    <th scope="row">Terminal Operator</th>
                    <td>TTI</td>
                  </tr>
                  <tr>
                    <th scope="row">Carriers Rotation</th>
                    <td>Lorem ipsum</td>
                  </tr>
                  <tr>
                    <th scope="row">Total Terminal Area</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Length of Berths</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Gantry Cranes</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Design Depth of Water</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Cargoes Handled</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">On-Dock Rail</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Special Equipment & Facilities</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Address</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Phone</th>
                    <td><a href="tel:1+1234567890">(123) 456-7890</a></td>
                  </tr>
                  <tr>
                    <th scope="row">FAX</th>
                    <td>Lorem ipsum dolor</td>
                  </tr>
                  <tr>
                    <th scope="row">Website</th>
                    <td><a href="https://polb.com">www.website.com</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Appointment System</th>
                    <td><a href="https://polb.com">www.website.com</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Location</th>
                    <td><a href="/">View on Map</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
             */}

            {/* =============================== */}
            {/* ==== START PORT STATISTICS ==== */}
            {/* =============================== */}

            {/* START Port Stats: TEUs Monthly */}
            <div className="content">
              <hr />
              <h2>Port Statistics: TEUs Monthly</h2>
            </div>

            <div className="table-wrapper">
            <table class="table table--type-2 table--teus-monthly">
              <caption>Latest Month Container Trade in TEUs</caption>
              <thead>
                <tr>
                  <th scope="col">Timing</th>
                  <th scope="col" colspan="3">January</th>
                  <th scope="col" colspan="3">Fiscal Year to Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col">2018</th>
                  <th scope="col">2018</th>
                  <th scope="col">% Change</th>
                  <th scope="col">2019</th>
                  <th scope="col">2018</th>
                  <th scope="col">% Change</th>
                </tr>
                <tr>
                  <th scope="row">Loading Inbound</th>
                  <td>375,908</td>
                  <td>375,908</td>
                  <td>375,908</td>
                  <td>375,908</td>
                  <td>375,908</td>
                  <td>375,908</td>
                </tr>
                <tr>
                  <th scope="row">Loading Outbound</th>
                  <td>113,329</td>
                  <td>113,329</td>
                  <td>113,329</td>
                  <td>113,329</td>
                  <td>113,329</td>
                  <td>113,329</td>
                </tr>
                <tr>
                  <th scope="row">Empties</th>
                  <td>255,220</td>
                  <td>255,220</td>
                  <td>255,220</td>
                  <td>255,220</td>
                  <td>255,220</td>
                  <td>255,220</td>
                </tr>
                <tr>
                  <th scope="row">Total (TEU)</th>
                  <td>741,647</td>
                  <td>741,647</td>
                  <td>741,647</td>
                  <td>741,647</td>
                  <td>741,647</td>
                  <td>741,647</td>
                </tr>
              </tbody>
            </table>
            </div>


            {/* ==== START Port Stats: Yearly TEU Totals ==== */}
            <div className="content">
              <hr />
              <h2>Port Statistics: Yearly TEU Totals</h2>
            </div>

            <div class="table-wrapper">
              <table class="table table--type-2 table--teus-yearly">
              <caption>Container Trade in TEUs*<br />Yearly TEU Totals</caption>
              <thead>
                <tr>
                  <th scope="col">Year</th>
                  <th scope="col">Loaded<br />
                  Inbound</th>
                  <th scope="col">Loaded<br />
                  Outbound</th>
                  <th scope="col">Total<br />
                  Loaded</th>
                  <th scope="col">Empties</th>
                  <th scope="col">Total<br />
                  Throughput</th>
                </tr>    
              </thead>
              <tbody>
                <tr>
                    <th scope="row">2018</th>
                    <td>4,097,377</td>
                    <td>1,523,008</td>
                    <td>5,620,386</td>
                    <td>2,470,638</td>
                    <td>8,091,023</td>
                </tr>
                <tr>
                    <th scope="row">2017</th>
                    <td>3,863,187</td>
                    <td>1,470,514</td>
                    <td>5,333,701</td>
                    <td>2,210,806</td>
                    <td>7,544,507</td>
                </tr>
                <tr>
                    <th scope="row">2016</th>
                    <td>3,442,575</td>
                    <td>1,529,497</td>
                    <td>4,972,073</td>
                    <td>1,803,098</td>
                    <td>6,775,171</td>
                </tr>
                <tr>
                    <th scope="row">2015</th>
                    <td>3,625,263</td>
                    <td>1,525,560</td>
                    <td>5,150,823</td>
                    <td>2,041,243</td>
                    <td>7,192,066</td>
                </tr>
                <tr>
                    <th scope="row">2014</th>
                    <td>3,517,514</td>
                    <td>1,604,394</td>
                    <td>5,121,908</td>
                    <td>1,698,898</td>
                    <td>6,820,806</td>
                </tr>
                <tr>
                    <th scope="row">2013</th>
                    <td>3,455,323</td>
                    <td>1,704,932</td>
                    <td>5,160,255</td>
                    <td>1,570,318</td>
                    <td>6,730,573</td>
                </tr>
                <tr>
                    <th scope="row">2012</th>
                    <td>3,062,290</td>
                    <td>1,540,188</td>
                    <td>4,602,478</td>
                    <td>1,443,184</td>
                    <td>6,045,662</td>
                </tr>
                <tr>
                    <th scope="row">2011</th>
                    <td>3,024,965</td>
                    <td>1,506,693</td>
                    <td>4,531,658</td>
                    <td>1,529,427</td>
                    <td>6,061,085</td>
                </tr>
                <tr>
                    <th scope="row">2010</th>
                    <td>3,128,860</td>
                    <td>1,562,398</td>
                    <td>4,691,258</td>
                    <td>1,572,241</td>
                    <td>6,263,499</td>
                </tr>
                <tr>
                    <th scope="row">2009</th>
                    <td>2,534,897</td>
                    <td>1,352,053</td>
                    <td>3,886,950</td>
                    <td>1,180,647</td>
                    <td>5,067,597</td>
                </tr>
                <tr>
                    <th scope="row">2008</th>
                    <td>3,189,363</td>
                    <td>1,687,051</td>
                    <td>4,876,414</td>
                    <td>1,611,402</td>
                    <td>6,487,816</td>
                </tr>
                <tr>
                    <th scope="row">2007</th>
                    <td>3,704,593</td>
                    <td>1,574,241</td>
                    <td>5,278,834</td>
                    <td>2,033,631</td>
                    <td>7,312,465</td>
                </tr>
                <tr>
                    <th scope="row">2006</th>
                    <td>3,719,680</td>
                    <td>1,290,843</td>
                    <td>5,010,523</td>
                    <td>2,279,842</td>
                    <td>7,290,365</td>
                </tr>
                <tr>
                    <th scope="row">2005</th>
                    <td>3,346,054</td>
                    <td>1,221,419</td>
                    <td>4,567,473</td>
                    <td>2,142,345</td>
                    <td>6,709,818</td>
                </tr>
                <tr>
                    <th scope="row">2004</th>
                    <td>2,987,980</td>
                    <td>1,007,913</td>
                    <td>3,995,893</td>
                    <td>1,783,959</td>
                    <td>5,779,852</td>
                </tr>
                <tr>
                    <th scope="row">2003</th>
                    <td>2,409,577</td>
                    <td>904,539</td>
                    <td>3,314,116</td>
                    <td>1,344,008</td>
                    <td>4,658,124</td>
                </tr>
                <tr>
                    <th scope="row">2002</th>
                    <td>2,452,490</td>
                    <td>855,202</td>
                    <td>3,307,692</td>
                    <td>1,218,673</td>
                    <td>4,526,365</td>
                </tr>
                <tr>
                    <th scope="row">2001</th>
                    <td>2,420,687</td>
                    <td>952,845</td>
                    <td>3,373,532</td>
                    <td>1,089,435</td>
                    <td>4,462,967</td>
                </tr>
                <tr>
                    <th scope="row">2000</th>
                    <td>2,456,188</td>
                    <td>1,044,353</td>
                    <td>3,500,541</td>
                    <td>1,100,246</td>
                    <td>4,600,787</td>
                </tr>
                <tr>
                    <th scope="row">1999</th>
                    <td>2,317,050</td>
                    <td>989,221</td>
                    <td>3,306,271</td>
                    <td>1,102,209</td>
                    <td>4,408,480</td>
                </tr>
                <tr>
                    <th scope="row">1998</th>
                    <td>2,096,901</td>
                    <td>973,647</td>
                    <td>3,070,548</td>
                    <td>1,027,141</td>
                    <td>4,097,689</td>
                </tr>
                <tr>
                    <th scope="row">1997</th>
                    <td>1,806,787</td>
                    <td>1,107,324</td>
                    <td>2,914,111</td>
                    <td>590,491</td>
                    <td>3,504,602</td>
                </tr>
                <tr>
                    <th scope="row">1996</th>
                    <td>1,547,578</td>
                    <td>1,081,887</td>
                    <td>2,629,465</td>
                    <td>437,869</td>
                    <td>3,067,334</td>
                </tr>
                <tr>
                    <th scope="row">1995</th>
                    <td>1,353,320</td>
                    <td>1,036,213</td>
                    <td>2,389,533</td>
                    <td>453,969</td>
                    <td>2,843,502</td>
                </tr>
              </tbody>
            </table>
            </div>            


{/* ==== START Port Statistics Table: TEUs Year to Date ==== 
http://polb.com/economics/stats/teus_ytd.asp
*/}
<div className="content">
  <hr />
  <h2>Port Statistics: TEUs Year to Date</h2>
</div>
<div class="table-wrapper">
  <table class="table table--type-2 table--teus-ytd">
  <thead>
    <tr>
      <th scope="col">Month</th>
      <th scope="col">Loaded<br /> Inbound</th>
      <th scope="col">Loaded<br /> Outbound</th>
      <th scope="col">Empties</th>
      <th scope="col">Total<br /> Teus</th>
    </tr>    
  </thead>
  <tbody>
    <tr>
        <th scope="row">January</th>
        <td>323,838</td>
        <td>117,288</td>
        <td>216,160</td>
        <td>657,286</td>
    </tr>
    <tr>
        <th scope="row">February</th>
        <td>302,865</td>
        <td>105,287</td>
        <td>188,465</td>
        <td>596,616</td>
    </tr>
    <tr>
        <th scope="row">March</th>
        <td>247,039</td>
        <td>131,436</td>
        <td>174,346</td>
        <td>552,821</td>
    </tr>
    <tr>
        <th scope="row">April</th>
        <td>317,883</td>
        <td>123,804</td>
        <td>186,435</td>
        <td>628,122</td>
    </tr>
    <tr>
        <th scope="row">May</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">June</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">July</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">August</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">September</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">October</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">November</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">December</th>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <th scope="row">Year-to-Date</th>
        <td>1,191,625</td>
        <td>477,815</td>
        <td>765,405</td>
        <td>2,434,845</td>
    </tr>
    <tr>
        <th scope="row">YTD % Change</th>
        <td>-4.4%</td>
        <td>-10.8%</td>
        <td>4.8%</td>
        <td>-3.1%</td>
    </tr>
  </tbody>
</table>
</div>


{/* ==== START Port Statistics: TEUs Archive Since 1995==== 
http://polb.com/economics/stats/teus_ytd.asp
*/}
<div className="content">
  <hr />
  <h2>Port Statistics: TEUs Archive Since 1995</h2>
</div>
<div class="table-wrapper">
  <table class="table table--type-2 table--teus-archive">
    <thead>
      <tr>
        <th scope="col">Month-Year</th>
        <th scope="col">Loaded <br />Inbound</th>
        <th scope="col">Loaded <br />Outbound</th>
        <th scope="col">Total <br />Loaded</th>
        <th scope="col">Empties</th>
        <th scope="col">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">Apr-19</th>
        <td>317,883</td>
        <td>123,804</td>
        <td>441,687</td>
        <td>186,435</td>
        <td>628,122</td>
      </tr>
      <tr>
        <th scope="row">Mar-19</th>
        <td>247,039</td>
        <td>131,436</td>
        <td>378,475</td>
        <td>174,346</td>
        <td>552,821</td>
      </tr>
      <tr>
        <th scope="row">Feb-19</th>
        <td>302,865</td>
        <td>105,287</td>
        <td>408,152</td>
        <td>188,465</td>
        <td>596,616</td>
      </tr>
      <tr>
        <th scope="row">Jan-19</th>
        <td>323,838</td>
        <td>117,288</td>
        <td>441,126</td>
        <td>216,160</td>
        <td>657,286</td>
      </tr>
      <tr>
        <th scope="row">Dec-18</th>
        <td>373,098</td>
        <td>113,329</td>
        <td>486,427</td>
        <td>255,220</td>
        <td>741,647</td>
      </tr>
      <tr>
        <th scope="row">Nov-18</th>
        <td>319,877</td>
        <td>115,774</td>
        <td>435,652</td>
        <td>186,183</td>
        <td>621,835</td>
      </tr>
      <tr>
        <th scope="row">Oct-18</th>
        <td>364,084</td>
        <td>119,837</td>
        <td>483,921</td>
        <td>221,487</td>
        <td>705,408</td>
      </tr>
      <tr>
        <th scope="row">Sep-18</th>
        <td>357,301</td>
        <td>121,561</td>
        <td>478,861</td>
        <td>222,343</td>
        <td>701,204</td>
      </tr>
      <tr>
        <th scope="row">Aug-18</th>
        <td>343,029</td>
        <td>119,546</td>
        <td>462,575</td>
        <td>216,968</td>
        <td>679,543</td>
      </tr>
      <tr>
        <th scope="row">Jul-18</th>
        <td>347,736</td>
        <td>119,747</td>
        <td>467,483</td>
        <td>220,975</td>
        <td>688,457</td>
      </tr>
      <tr>
        <th scope="row">Jun-18</th>
        <td>384,095</td>
        <td>135,168</td>
        <td>519,262</td>
        <td>232,926</td>
        <td>752,188</td>
      </tr>
      <tr>
        <th scope="row">May-18</th>
        <td>361,056</td>
        <td>142,412</td>
        <td>503,468</td>
        <td>183,959</td>
        <td>687,427</td>
      </tr>
      <tr>
        <th scope="row">Apr-18</th>
        <td>312,376</td>
        <td>141,799</td>
        <td>454,174</td>
        <td>164,264</td>
        <td>618,438</td>
      </tr>
      <tr>
        <th scope="row">Mar-18</th>
        <td>267,824</td>
        <td>142,419</td>
        <td>410,243</td>
        <td>165,015</td>
        <td>575,258</td>
      </tr>
      <tr>
        <th scope="row">Feb-18</th>
        <td>342,247</td>
        <td>130,916</td>
        <td>473,162</td>
        <td>188,628</td>
        <td>661,790</td>
      </tr>
      <tr>
        <th scope="row">Jan-18</th>
        <td>324,656</td>
        <td>120,503</td>
        <td>445,159</td>
        <td>212,671</td>
        <td>657,830</td>
      </tr>
      <tr>
        <th scope="row">Dec-17</th>
        <td>345,721</td>
        <td>137,449</td>
        <td>483,169</td>
        <td>213,749</td>
        <td>696,918</td>
      </tr>
      <tr>
        <th scope="row">Nov-17</th>
        <td>319,210</td>
        <td>126,364</td>
        <td>445,574</td>
        <td>167,085</td>
        <td>612,659</td>
      </tr>
      <tr>
        <th scope="row">Oct-17</th>
        <td>339,013</td>
        <td>126,150</td>
        <td>465,163</td>
        <td>204,055</td>
        <td>669,218</td>
      </tr>
      <tr>
        <th scope="row">Sep-17</th>
        <td>366,298</td>
        <td>125,336</td>
        <td>491,634</td>
        <td>209,985</td>
        <td>701,619</td>
      </tr>
      <tr>
        <th scope="row">Aug-17</th>
        <td>355,715</td>
        <td>117,290</td>
        <td>473,005</td>
        <td>219,370</td>
        <td>692,375</td>
      </tr>
      <tr>
        <th scope="row">Jul-17</th>
        <td>378,820</td>
        <td>126,098</td>
        <td>504,918</td>
        <td>215,394</td>
        <td>720,312</td>
      </tr>
      <tr>
        <th scope="row">Jun-17</th>
        <td>335,328</td>
        <td>118,304</td>
        <td>453,632</td>
        <td>205,095</td>
        <td>658,727</td>
      </tr>
      <tr>
        <th scope="row">May-17</th>
        <td>336,594</td>
        <td>118,786</td>
        <td>455,380</td>
        <td>192,908</td>
        <td>648,287</td>
      </tr>
      <tr>
        <th scope="row">Apr-17</th>
        <td>288,207</td>
        <td>116,260</td>
        <td>404,467</td>
        <td>153,547</td>
        <td>558,014</td>
      </tr>
      <tr>
        <th scope="row">Mar-17</th>
        <td>249,534</td>
        <td>120,435</td>
        <td>369,969</td>
        <td>135,413</td>
        <td>505,382</td>
      </tr>
      <tr>
        <th scope="row">Feb-17</th>
        <td>249,759</td>
        <td>119,811</td>
        <td>369,569</td>
        <td>128,742</td>
        <td>498,311</td>
      </tr>
      <tr>
        <th scope="row">Jan-17</th>
        <td>298,990</td>
        <td>118,234</td>
        <td>417,224</td>
        <td>165,465</td>
        <td>582,689</td>
      </tr>
      <tr>
        <th scope="row">Dec-16</th>
        <td>271,599</td>
        <td>122,933</td>
        <td>394,532</td>
        <td>154,397</td>
        <td>548,929</td>
      </tr>
      <tr>
        <th scope="row">Nov-16</th>
        <td>270,610</td>
        <td>120,897</td>
        <td>391,507</td>
        <td>142,801</td>
        <td>534,308</td>
      </tr>
      <tr>
        <th scope="row">Oct-16</th>
        <td>296,711</td>
        <td>126,770</td>
        <td>423,481</td>
        <td>158,327</td>
        <td>581,808</td>
      </tr>
      <tr>
        <th scope="row">Sep-16</th>
        <td>282,945</td>
        <td>120,383</td>
        <td>403,328</td>
        <td>143,476</td>
        <td>546,805</td>
      </tr>
      <tr>
        <th scope="row">Aug-16</th>
        <td>321,625</td>
        <td>159,247</td>
        <td>480,872</td>
        <td>160,157</td>
        <td>641,029</td>
      </tr>
      <tr>
        <th scope="row">Jul-16</th>
        <td>325,608</td>
        <td>142,812</td>
        <td>468,420</td>
        <td>168,671</td>
        <td>637,091</td>
      </tr>
      <tr>
        <th scope="row">Jun-16</th>
        <td>313,526</td>
        <td>128,099</td>
        <td>441,625</td>
        <td>161,714</td>
        <td>603,339</td>
      </tr>
      <tr>
        <th scope="row">May-16</th>
        <td>330,639</td>
        <td>138,594</td>
        <td>469,233</td>
        <td>171,333</td>
        <td>640,566</td>
      </tr>
      <tr>
        <th scope="row">Apr-16</th>
        <td>247,316</td>
        <td>112,805</td>
        <td>360,121</td>
        <td>118,721</td>
        <td>478,842</td>
      </tr>
      <tr>
        <th scope="row">Mar-16</th>
        <td>207,635</td>
        <td>127,210</td>
        <td>334,845</td>
        <td>130,010</td>
        <td>464,855</td>
      </tr>
      <tr>
        <th scope="row">Feb-16</th>
        <td>295,870</td>
        <td>123,010</td>
        <td>418,880</td>
        <td>142,532</td>
        <td>561,412</td>
      </tr>
      <tr>
        <th scope="row">Jan-16</th>
        <td>278,491</td>
        <td>106,739</td>
        <td>385,230</td>
        <td>150,958</td>
        <td>536,188</td>
      </tr>
      <tr>
        <th scope="row">Dec-15</th>
        <td>296,002</td>
        <td>126,118</td>
        <td>422,120</td>
        <td>174,328</td>
        <td>596,448</td>
      </tr>
      <tr>
        <th scope="row">Nov-15</th>
        <td>306,654</td>
        <td>124,717</td>
        <td>431,371</td>
        <td>188,328</td>
        <td>619,699</td>
      </tr>
      <tr>
        <th scope="row">Oct-15</th>
        <td>307,995</td>
        <td>128,308</td>
        <td>436,303</td>
        <td>183,681</td>
        <td>619,983</td>
      </tr>
      <tr>
        <th scope="row">Sep-15</th>
        <td>332,909</td>
        <td>125,639</td>
        <td>458,548</td>
        <td>197,076</td>
        <td>655,624</td>
      </tr>
      <tr>
        <th scope="row">Aug-15</th>
        <td>358,262</td>
        <td>138,765</td>
        <td>497,027</td>
        <td>206,625</td>
        <td>703,652</td>
      </tr>
      <tr>
        <th scope="row">Jul-15</th>
        <td>345,912</td>
        <td>143,875</td>
        <td>489,787</td>
        <td>200,457</td>
        <td>690,245</td>
      </tr>
      <tr>
        <th scope="row">Jun-15</th>
        <td>297,189</td>
        <td>128,223</td>
        <td>425,412</td>
        <td>158,209</td>
        <td>583,621</td>
      </tr>
      <tr>
        <th scope="row">May-15</th>
        <td>327,317</td>
        <td>135,855</td>
        <td>463,172</td>
        <td>172,078</td>
        <td>635,250</td>
      </tr>
      <tr>
        <th scope="row">Apr-15</th>
        <td>317,376</td>
        <td>137,546</td>
        <td>454,922</td>
        <td>159,938</td>
        <td>614,860</td>
      </tr>
      <tr>
        <th scope="row">Mar-15</th>
        <td>317,520</td>
        <td>127,337</td>
        <td>444,857</td>
        <td>185,227</td>
        <td>630,084</td>
      </tr>
      <tr>
        <th scope="row">Feb-15</th>
        <td>204,462</td>
        <td>110,711</td>
        <td>315,173</td>
        <td>97,941</td>
        <td>413,114</td>
      </tr>
      <tr>
        <th scope="row">Jan-15</th>
        <td>213,667</td>
        <td>98,462</td>
        <td>312,129</td>
        <td>117,361</td>
        <td>429,490</td>
      </tr>
      <tr>
        <th scope="row">Dec-14</th>
        <td>276,516</td>
        <td>131,496 </td>
        <td>408,012 </td>
        <td>159,225 </td>
        <td>567,237 </td>
      </tr>
      <tr>
        <th scope="row">Nov-14</th>
        <td>293,984 </td>
        <td>129,960 </td>
        <td>423,944 </td>
        <td>157,570 </td>
        <td>581,514 </td>
      </tr>
      <tr>
        <th scope="row">Oct-14</th>
        <td>310,482 </td>
        <td>120,445 </td>
        <td>430927</td>
        <td>152,082 </td>
        <td>583,009 </td>
      </tr>
      <tr>
        <th scope="row">Sep-14</th>
        <td>339,343</td>
        <td>118,412</td>
        <td>457,755</td>
        <td>172,016</td>
        <td>629,771</td>
      </tr>
      <tr>
        <th scope="row">Aug-14</th>
        <td>300,851</td>
        <td>126,856</td>
        <td>427,707</td>
        <td>145,376</td>
        <td>573,083</td>
      </tr>
      <tr>
        <th scope="row">Jul-14</th>
        <td>297,615</td>
        <td>124,126</td>
        <td>421,741</td>
        <td>161,319</td>
        <td>583,060</td>
      </tr>
      <tr>
        <th scope="row">Jun-14</th>
        <td>316,054</td>
        <td>140,034</td>
        <td>456,088</td>
        <td>154,428</td>
        <td>610,515</td>
      </tr>
      <tr>
        <th scope="row">May-14</th>
        <td>312,439</td>
        <td>146,702</td>
        <td>459,141</td>
        <td>140,368</td>
        <td>599,509</td>
      </tr>
      <tr>
        <th scope="row">Apr-14</th>
        <td>295,712</td>
        <td>146,498</td>
        <td>442,210</td>
        <td>127,633</td>
        <td>569,843</td>
      </tr>
      <tr>
        <th scope="row">Mar-14</th>
        <td>223,432</td>
        <td>153,883</td>
        <td>377,315</td>
        <td>99,894</td>
        <td>477,209</td>
      </tr>
      <tr>
        <th scope="row">Feb-14</th>
        <td>271,671</td>
        <td>143,572</td>
        <td>415,243</td>
        <td>101,930</td>
        <td>517,173</td>
      </tr>
      <tr>
        <th scope="row">Jan-14</th>
        <td>279,415</td>
        <td>122,411</td>
        <td>401,826</td>
        <td>127,058</td>
        <td>528,884</td>
      </tr>
      <tr>
        <th scope="row">Dec-13</th>
        <td>291,434</td>
        <td>148,150</td>
        <td>439,584</td>
        <td>142,859</td>
        <td>582,443</td>
      </tr>
      <tr>
        <th scope="row">Nov-13</th>
        <td>296,638</td>
        <td>151,950</td>
        <td>448,588</td>
        <td>121,011</td>
        <td>569,599</td>
      </tr>
      <tr>
        <th scope="row">Oct-13</th>
        <td>298,271</td>
        <td>141,457</td>
        <td>439,728</td>
        <td>136,774</td>
        <td>576,502</td>
      </tr>
      <tr>
        <th scope="row">Sep-13</th>
        <td>307,981</td>
        <td>134,676</td>
        <td>442,657</td>
        <td>144,457</td>
        <td>587,114</td>
      </tr>
      <tr>
        <th scope="row">Aug-13</th>
        <td>327,817</td>
        <td>154,118</td>
        <td>481,935</td>
        <td>148,357</td>
        <td>630,292</td>
      </tr>
      <tr>
        <th scope="row">Jul-13</th>
        <td>294,926</td>
        <td>132,290</td>
        <td>427,216</td>
        <td>134,950</td>
        <td>562,166</td>
      </tr>
      <tr>
        <th scope="row">Jun-13</th>
        <td>290,448</td>
        <td>133,800</td>
        <td>424,248</td>
        <td>141,228</td>
        <td>565,476</td>
      </tr>
      <tr>
        <th scope="row">May-13</th>
        <td>305,498</td>
        <td>147,073</td>
        <td>452,571</td>
        <td>131,017</td>
        <td>583,588</td>
      </tr>
      <tr>
        <th scope="row">Apr-13</th>
        <td>264,337</td>
        <td>137,864</td>
        <td>402,201</td>
        <td>117,263</td>
        <td>519,464</td>
      </tr>
      <tr>
        <th scope="row">Mar-13</th>
        <td>224,913</td>
        <td>156,212</td>
        <td>381,125</td>
        <td>105,574</td>
        <td>486,699</td>
      </tr>
      <tr>
        <th scope="row">Feb-13</th>
        <td>279,144</td>
        <td>140,626</td>
        <td>419,770</td>
        <td>111,197</td>
        <td>530,967</td>
      </tr>
      <tr>
        <th scope="row">Jan-13</th>
        <td>273,918</td>
        <td>126,714</td>
        <td>400,632</td>
        <td>135,631</td>
        <td>536,263</td>
      </tr>
      <tr>
        <th scope="row">Dec-12</th>
        <td>295,579</td>
        <td>135,561</td>
        <td>431,140</td>
        <td>128,980</td>
        <td>560,120</td>
      </tr>
      <tr>
        <th scope="row">Nov-12</th>
        <td>278,534</td>
        <td>138,312</td>
        <td>416,846</td>
        <td>138,667</td>
        <td>555,513</td>
      </tr>
      <tr>
        <th scope="row">Oct-12</th>
        <td>276,698</td>
        <td>133,503</td>
        <td>410,201</td>
        <td>120,112</td>
        <td>530,313</td>
      </tr>
      <tr>
        <th scope="row">Sep-12</th>
        <td>265,102</td>
        <td>114,902</td>
        <td>380,004</td>
        <td>132,016</td>
        <td>512,020</td>
      </tr>
      <tr>
        <th scope="row">Aug-12</th>
        <td>274,977</td>
        <td>128,225</td>
        <td>403,202</td>
        <td>140,243</td>
        <td>543,445</td>
      </tr>
      <tr>
        <th scope="row">Jul-12</th>
        <td>261,233</td>
        <td>124,574</td>
        <td>385,807</td>
        <td>136,679</td>
        <td>522,486</td>
      </tr>
      <tr>
        <th scope="row">Jun-12</th>
        <td>280,526</td>
        <td>133,649</td>
        <td>414,175</td>
        <td>141,184</td>
        <td>555,359</td>
      </tr>
      <tr>
        <th scope="row">May-12</th>
        <td>249,937</td>
        <td>129,083</td>
        <td>379,020</td>
        <td>118,872</td>
        <td>497,892</td>
      </tr>
      <tr>
        <th scope="row">Apr-12</th>
        <td>232,963</td>
        <td>120,452</td>
        <td>353,415</td>
        <td>108,496</td>
        <td>461,911</td>
      </tr>
      <tr>
        <th scope="row">Mar-12</th>
        <td>226,141</td>
        <td>144,838</td>
        <td>370,979</td>
        <td>90,611</td>
        <td>461,590</td>
      </tr>
      <tr>
        <th scope="row">Feb-12</th>
        <td>191,475</td>
        <td>120,006</td>
        <td>311,481</td>
        <td>77,108</td>
        <td>388,589</td>
      </tr>
      <tr>
        <th scope="row">Jan-12</th>
        <td>229,125</td>
        <td>117,083</td>
        <td>346,208</td>
        <td>110,216</td>
        <td>456,424</td>
      </tr>
      <tr>
        <th scope="row">Dec-11</th>
        <td>248,609</td>
        <td>129,229</td>
        <td>377,838</td>
        <td>132,106</td>
        <td>509,944</td>
      </tr>
      <tr>
        <th scope="row">Nov-11</th>
        <td>231,749</td>
        <td>111,011</td>
        <td>342,760</td>
        <td>117,121</td>
        <td>459,881</td>
      </tr>
      <tr>
        <th scope="row">Oct-11</th>
        <td>240,248</td>
        <td>118,325</td>
        <td>358,573</td>
        <td>129,092</td>
        <td>487,665</td>
      </tr>
      <tr>
        <th scope="row">Sep-11</th>
        <td>263,214</td>
        <td>118,214</td>
        <td>381,428</td>
        <td>145,747</td>
        <td>527,175</td>
      </tr>
      <tr>
        <th scope="row">Aug-11</th>
        <td>267,198</td>
        <td>121,277</td>
        <td>388,475</td>
        <td>147,454</td>
        <td>535,929</td>
      </tr>
      <tr>
        <th scope="row">Jul-11</th>
        <td>290,314</td>
        <td>126,968</td>
        <td>417,282</td>
        <td>155,644</td>
        <td>572,926</td>
      </tr>
      <tr>
        <th scope="row">Jun-11</th>
        <td>271,113</td>
        <td>126,588</td>
        <td>397,701</td>
        <td>156,568</td>
        <td>554,269</td>
      </tr>
      <tr>
        <th scope="row">May-11</th>
        <td>275,100</td>
        <td>130,161</td>
        <td>405,261</td>
        <td>131,420</td>
        <td>536,681</td>
      </tr>
      <tr>
        <th scope="row">Apr-11</th>
        <td>270,107</td>
        <td>143,683</td>
        <td>413,790</td>
        <td>117,300</td>
        <td>531,090</td>
      </tr>
      <tr>
        <th scope="row">Mar-11</th>
        <td>191,211</td>
        <td>131,761</td>
        <td>322,972</td>
        <td>89,263</td>
        <td>412,235</td>
      </tr>
      <tr>
        <th scope="row">Feb-11</th>
        <td>233,660</td>
        <td>121,929</td>
        <td>355,589</td>
        <td>102,747</td>
        <td>458,336</td>
      </tr>
      <tr>
        <th scope="row">Jan-11</th>
        <td>242,445</td>
        <td>127,546</td>
        <td>369,991</td>
        <td>104,969</td>
        <td>474,960</td>
      </tr>
      <tr>
        <th scope="row">Dec-10</th>
        <td>256,889</td>
        <td>141,140</td>
        <td>398,029</td>
        <td>125,282</td>
        <td>523,311</td>
      </tr>
      <tr>
        <th scope="row">Nov-10</th>
        <td>274,480</td>
        <td>142,628</td>
        <td>417,108</td>
        <td>141,199</td>
        <td>558,307</td>
      </tr>
      <tr>
        <th scope="row">Oct-10</th>
        <td>303,168</td>
        <td>150,581</td>
        <td>453,749</td>
        <td>159,872</td>
        <td>613,621</td>
      </tr>
      <tr>
        <th scope="row">Sep-10</th>
        <td>288,905</td>
        <td>124,021</td>
        <td>412,926</td>
        <td>161,864</td>
        <td>574,790</td>
      </tr>
      <tr>
        <th scope="row">Aug-10</th>
        <td>311,240</td>
        <td>126,039</td>
        <td>437,279</td>
        <td>173,723</td>
        <td>611,002</td>
      </tr>
      <tr>
        <th scope="row">Jul-10</th>
        <td>293,878</td>
        <td>126,177</td>
        <td>420,055</td>
        <td>167,826</td>
        <td>587,881</td>
      </tr>
      <tr>
        <th scope="row">Jun-10</th>
        <td>262,053</td>
        <td>116,112</td>
        <td>378,165</td>
        <td>141,935</td>
        <td>520,100</td>
      </tr>
      <tr>
        <th scope="row">May-10</th>
        <td>264,505</td>
        <td>138,659</td>
        <td>403,164</td>
        <td>121,551</td>
        <td>524,715</td>
      </tr>
      <tr>
        <th scope="row">Apr-10</th>
        <td>241,245</td>
        <td>130,155</td>
        <td>371,400</td>
        <td>113,659</td>
        <td>485,059</td>
      </tr>
      <tr>
        <th scope="row">Mar-10</th>
        <td>206,652</td>
        <td>130,495</td>
        <td>337,147</td>
        <td>85,627</td>
        <td>422,774</td>
      </tr>
      <tr>
        <th scope="row">Feb-10</th>
        <td>207,920</td>
        <td>123,208</td>
        <td>331,128</td>
        <td>82,006</td>
        <td>413,134</td>
      </tr>
      <tr>
        <th scope="row">Jan-10</th>
        <td>217,925</td>
        <td>113,183</td>
        <td>331,108</td>
        <td>97,697</td>
        <td>428,805</td>
      </tr>
      <tr>
        <th scope="row">Dec-09</th>
        <td>232,586</td>
        <td>123,084</td>
        <td>355,670</td>
        <td>111,567</td>
        <td>467,237</td>
      </tr>
      <tr>
        <th scope="row">Nov-09</th>
        <td>228,347</td>
        <td>114,283</td>
        <td>342,630</td>
        <td>105,521</td>
        <td>448,151</td>
      </tr>
      <tr>
        <th scope="row">Oct-09</th>
        <td>227,064</td>
        <td>119,194</td>
        <td>346,258</td>
        <td>106,160</td>
        <td>452,418</td>
      </tr>
      <tr>
        <th scope="row">Sep-09</th>
        <td>224,924</td>
        <td>109,337</td>
        <td>334,261</td>
        <td>106,103</td>
        <td>440,364</td>
      </tr>
      <tr>
        <th scope="row">Aug-09</th>
        <td>249,920</td>
        <td>130,623</td>
        <td>380,543</td>
        <td>112,796</td>
        <td>493,339</td>
      </tr>
      <tr>
        <th scope="row">Jul-09</th>
        <td>221,719</td>
        <td>108,420</td>
        <td>330,139</td>
        <td>102,874</td>
        <td>433,013</td>
      </tr>
      <tr>
        <th scope="row">Jun-09</th>
        <td>206,358</td>
        <td>114,107</td>
        <td>320,465</td>
        <td>92,882</td>
        <td>413,347</td>
      </tr>
      <tr>
        <th scope="row">May-09</th>
        <td>208,590</td>
        <td>121,065</td>
        <td>329,655</td>
        <td>89,900</td>
        <td>419,555</td>
      </tr>
      <tr>
        <th scope="row">Apr-09</th>
        <td>199,051</td>
        <td>112,976</td>
        <td>312,027</td>
        <td>96,678</td>
        <td>408,705</td>
      </tr>
      <tr>
        <th scope="row">Mar-09</th>
        <td>186,450</td>
        <td>117,674</td>
        <td>304,124</td>
        <td>70,007</td>
        <td>374,131</td>
      </tr>
      <tr>
        <th scope="row">Feb-09</th>
        <td>149,299</td>
        <td>92,781</td>
        <td>242,080</td>
        <td>75,962</td>
        <td>318,042</td>
      </tr>
      <tr>
        <th scope="row">Jan-09</th>
        <td>200,588</td>
        <td>88,510</td>
        <td>289,098</td>
        <td>110,197</td>
        <td>399,295</td>
      </tr>
      <tr>
        <th scope="row">Dec-08</th>
        <td>205,031</td>
        <td>94,009</td>
        <td>299,040</td>
        <td>130,906</td>
        <td>429,946</td>
      </tr>
      <tr>
        <th scope="row">Nov-08</th>
        <td>267,840</td>
        <td>109,850</td>
        <td>377,690</td>
        <td>179,769</td>
        <td>557,459</td>
      </tr>
      <tr>
        <th scope="row">Oct-08</th>
        <td>292,456</td>
        <td>132,521</td>
        <td>424,977</td>
        <td>170,212</td>
        <td>595,189</td>
      </tr>
      <tr>
        <th scope="row">Sep-08</th>
        <td>279,137</td>
        <td>129,630</td>
        <td>408,767</td>
        <td>146,070</td>
        <td>554,837</td>
      </tr>
      <tr>
        <th scope="row">Aug-08</th>
        <td>283,174</td>
        <td>153,467</td>
        <td>436,641</td>
        <td>135,615</td>
        <td>572,256</td>
      </tr>
      <tr>
        <th scope="row">Jul-08</th>
        <td>272,350</td>
        <td>153,364</td>
        <td>425,714</td>
        <td>137,989</td>
        <td>563,703</td>
      </tr>
      <tr>
        <th scope="row">Jun-08</th>
        <td>288,339</td>
        <td>160,180</td>
        <td>448,519</td>
        <td>131,156</td>
        <td>579,675</td>
      </tr>
      <tr>
        <th scope="row">May-08</th>
        <td>267,381</td>
        <td>158,798</td>
        <td>426,179</td>
        <td>102,832</td>
        <td>529,011</td>
      </tr>
      <tr>
        <th scope="row">Apr-08</th>
        <td>280,553</td>
        <td>163,577</td>
        <td>444,130</td>
        <td>112,455</td>
        <td>556,585</td>
      </tr>
      <tr>
        <th scope="row">Mar-08</th>
        <td>228,328</td>
        <td>162,679</td>
        <td>391,007</td>
        <td>107,107</td>
        <td>498,114</td>
      </tr>
      <tr>
        <th scope="row">Feb-08</th>
        <td>263,231</td>
        <td>147,275</td>
        <td>410,506</td>
        <td>119,193</td>
        <td>529,699</td>
      </tr>
      <tr>
        <th scope="row">Jan-08</th>
        <td>261,543</td>
        <td>121,701</td>
        <td>383,244</td>
        <td>138,098</td>
        <td>521,342</td>
      </tr>
      <tr>
        <th scope="row">Dec-07</th>
        <td>280,482</td>
        <td>142,940</td>
        <td>423,422</td>
        <td>151,933</td>
        <td>575,355</td>
      </tr>
      <tr>
        <th scope="row">Nov-07</th>
        <td>310,068</td>
        <td>143,848</td>
        <td>453,916</td>
        <td>157,690</td>
        <td>611,606</td>
      </tr>
      <tr>
        <th scope="row">Oct-07</th>
        <td>323,131</td>
        <td>144,839</td>
        <td>467,970</td>
        <td>176,603</td>
        <td>644,573</td>
      </tr>
      <tr>
        <th scope="row">Sep-07</th>
        <td>331,507</td>
        <td>134,842</td>
        <td>466,349</td>
        <td>187,760</td>
        <td>654,109</td>
      </tr>
      <tr>
        <th scope="row">Aug-07</th>
        <td>326,646</td>
        <td>141,410</td>
        <td>468,056</td>
        <td>189,848</td>
        <td>657,904</td>
      </tr>
      <tr>
        <th scope="row">Jul-07</th>
        <td>332,402</td>
        <td>135,089</td>
        <td>467,491</td>
        <td>179,937</td>
        <td>647,428</td>
      </tr>
      <tr>
        <th scope="row">Jun-07</th>
        <td>339,411</td>
        <td>140,967</td>
        <td>480,378</td>
        <td>181,841</td>
        <td>662,219</td>
      </tr>
      <tr>
        <th scope="row">May-07</th>
        <td>316,666</td>
        <td>127,061</td>
        <td>443,727</td>
        <td>162,529</td>
        <td>606,256</td>
      </tr>
      <tr>
        <th scope="row">Apr-07</th>
        <td>303,138</td>
        <td>121,191</td>
        <td>424,329</td>
        <td>154,906</td>
        <td>579,235</td>
      </tr>
      <tr>
        <th scope="row">Mar-07</th>
        <td>252,998</td>
        <td>125,529</td>
        <td>378,527</td>
        <td>162,580</td>
        <td>541,107</td>
      </tr>
      <tr>
        <th scope="row">Feb-07</th>
        <td>284,800</td>
        <td>108,215</td>
        <td>393,015</td>
        <td>148,189</td>
        <td>541,204</td>
      </tr>
      <tr>
        <th scope="row">Jan-07</th>
        <td>303,344</td>
        <td>108,310</td>
        <td>411,654</td>
        <td>179,815</td>
        <td>591,469</td>
      </tr>
      <tr>
        <th scope="row">Dec-06</th>
        <td>282,726</td>
        <td>111,838</td>
        <td>394,564</td>
        <td>180,663</td>
        <td>575,227</td>
      </tr>
      <tr>
        <th scope="row">Nov-06</th>
        <td>332,806</td>
        <td>107,738</td>
        <td>440,544</td>
        <td>214,453</td>
        <td>654,997</td>
      </tr>
      <tr>
        <th scope="row">Oct-06</th>
        <td>335,688</td>
        <td>109,318</td>
        <td>445,006</td>
        <td>205,720</td>
        <td>650,726</td>
      </tr>
      <tr>
        <th scope="row">Sep-06</th>
        <td>317,661</td>
        <td>98,660</td>
        <td>416,321</td>
        <td>198,418</td>
        <td>614,739</td>
      </tr>
      <tr>
        <th scope="row">Aug-06</th>
        <td>347,829</td>
        <td>105,256</td>
        <td>453,085</td>
        <td>221,973</td>
        <td>675,058</td>
      </tr>
      <tr>
        <th scope="row">Jul-06</th>
        <td>305,117</td>
        <td>100,861</td>
        <td>405,978</td>
        <td>176,947</td>
        <td>582,925</td>
      </tr>
      <tr>
        <th scope="row">Jun-06</th>
        <td>319,737</td>
        <td>101,828</td>
        <td>421,565</td>
        <td>195,437</td>
        <td>617,002</td>
      </tr>
      <tr>
        <th scope="row">May-06</th>
        <td>328,314</td>
        <td>115,158</td>
        <td>443,472</td>
        <td>200,594</td>
        <td>644,066</td>
      </tr>
      <tr>
        <th scope="row">Apr-06</th>
        <td>321,044</td>
        <td>111,755</td>
        <td>432,799</td>
        <td>186,713</td>
        <td>619,512</td>
      </tr>
      <tr>
        <th scope="row">Mar-06</th>
        <td>282,440</td>
        <td>118,728</td>
        <td>401,168</td>
        <td>160,026</td>
        <td>561,194</td>
      </tr>
      <tr>
        <th scope="row">Feb-06</th>
        <td>252,463</td>
        <td>109,963</td>
        <td>362,426</td>
        <td>153,038</td>
        <td>515,464</td>
      </tr>
      <tr>
        <th scope="row">Jan-06</th>
        <td>293,855</td>
        <td>99,740</td>
        <td>393,595</td>
        <td>185,860</td>
        <td>579,455</td>
      </tr>
      <tr>
        <th scope="row">Dec-05</th>
        <td>266,358</td>
        <td>104,184</td>
        <td>370,542</td>
        <td>175,230</td>
        <td>545,772</td>
      </tr>
      <tr>
        <th scope="row">Nov-05</th>
        <td>305,065</td>
        <td>107,147</td>
        <td>412,212</td>
        <td>198,432</td>
        <td>610,644</td>
      </tr>
      <tr>
        <th scope="row">Oct-05</th>
        <td>299,203</td>
        <td>103,422</td>
        <td>402,625</td>
        <td>198,315</td>
        <td>600,940</td>
      </tr>
      <tr>
        <th scope="row">Sep-05</th>
        <td>313,460</td>
        <td>97,009</td>
        <td>410,469</td>
        <td>199,800</td>
        <td>610,269</td>
      </tr>
      <tr>
        <th scope="row">Aug-05</th>
        <td>306,124</td>
        <td>110,750</td>
        <td>416,874</td>
        <td>198,487</td>
        <td>615,361</td>
      </tr>
      <tr>
        <th scope="row">Jul-05</th>
        <td>289,518</td>
        <td>106,917</td>
        <td>396,435</td>
        <td>176,865</td>
        <td>573,300</td>
      </tr>
      <tr>
        <th scope="row">Jun-05</th>
        <td>294,252</td>
        <td>101,381</td>
        <td>395,633</td>
        <td>180,971</td>
        <td>576,604</td>
      </tr>
      <tr>
        <th scope="row">May-05</th>
        <td>288,031</td>
        <td>106,034</td>
        <td>394,065</td>
        <td>170,821</td>
        <td>564,886</td>
      </tr>
      <tr>
        <th scope="row">Apr-05</th>
        <td>270,920</td>
        <td>107,609</td>
        <td>378,529</td>
        <td>159,972</td>
        <td>538,501</td>
      </tr>
      <tr>
        <th scope="row">Mar-05</th>
        <td>210,093</td>
        <td>104,519</td>
        <td>314,612</td>
        <td>151,407</td>
        <td>466,019</td>
      </tr>
      <tr>
        <th scope="row">Feb-05</th>
        <td>250,245</td>
        <td>90,867</td>
        <td>341,112</td>
        <td>156,813</td>
        <td>497,925</td>
      </tr>
      <tr>
        <th scope="row">Jan-05</th>
        <td>252,785</td>
        <td>81,580</td>
        <td>334,365</td>
        <td>175,232</td>
        <td>509,597</td>
      </tr>
      <tr>
        <th scope="row">Dec-04</th>
        <td>269,495</td>
        <td>93,377</td>
        <td>362,872</td>
        <td>188,467</td>
        <td>551,339</td>
      </tr>
      <tr>
        <th scope="row">Nov-04</th>
        <td>295,717</td>
        <td>99,436</td>
        <td>395,153</td>
        <td>187,461</td>
        <td>582,614</td>
      </tr>
      <tr>
        <th scope="row">Oct-04</th>
        <td>288,590</td>
        <td>85,824</td>
        <td>374,414</td>
        <td>183,251</td>
        <td>557,665</td>
      </tr>
      <tr>
        <th scope="row">Sep-04</th>
        <td>255,592</td>
        <td>76,190</td>
        <td>331,782</td>
        <td>162,594</td>
        <td>494,376</td>
      </tr>
      <tr>
        <th scope="row">Aug-04</th>
        <td>274,914</td>
        <td>79,339</td>
        <td>354,253</td>
        <td>166,651</td>
        <td>520,904</td>
      </tr>
      <tr>
        <th scope="row">Jul-04</th>
        <td>281,817</td>
        <td>82,441</td>
        <td>364,258</td>
        <td>165,405</td>
        <td>529,663</td>
      </tr>
      <tr>
        <th scope="row">Jun-04</th>
        <td>260,752</td>
        <td>82,218</td>
        <td>342,970</td>
        <td>151,128</td>
        <td>494,098</td>
      </tr>
      <tr>
        <th scope="row">May-04</th>
        <td>242,910</td>
        <td>84,502</td>
        <td>327,412</td>
        <td>140,617</td>
        <td>468,029</td>
      </tr>
      <tr>
        <th scope="row">Apr-04</th>
        <td>227,046</td>
        <td>79,825</td>
        <td>306,871</td>
        <td>134,190</td>
        <td>441,061</td>
      </tr>
      <tr>
        <th scope="row">Mar-04</th>
        <td>235,500</td>
        <td>94,823</td>
        <td>330,323</td>
        <td>118,075</td>
        <td>448,398</td>
      </tr>
      <tr>
        <th scope="row">Feb-04</th>
        <td>153,995</td>
        <td>76,963</td>
        <td>230,958</td>
        <td>83,791</td>
        <td>314,749</td>
      </tr>
      <tr>
        <th scope="row">Jan-04</th>
        <td>201,652</td>
        <td>72,975</td>
        <td>274,627</td>
        <td>102,329</td>
        <td>376,956</td>
      </tr>
      <tr>
        <th scope="row">Dec-03</th>
        <td>210,094</td>
        <td>83,941</td>
        <td>294,035</td>
        <td>111,986</td>
        <td>406,021</td>
      </tr>
      <tr>
        <th scope="row">Nov-03</th>
        <td>216,379</td>
        <td>82,449</td>
        <td>298,828</td>
        <td>126,065</td>
        <td>424,893</td>
      </tr>
      <tr>
        <th scope="row">Oct-03</th>
        <td>230,527</td>
        <td>84,952</td>
        <td>315,479</td>
        <td>121,345</td>
        <td>436,824</td>
      </tr>
      <tr>
        <th scope="row">Sep-03</th>
        <td>206,315</td>
        <td>65,926</td>
        <td>272,241</td>
        <td>107,570</td>
        <td>379,811</td>
      </tr>
      <tr>
        <th scope="row">Aug-03</th>
        <td>206,910</td>
        <td>75,338</td>
        <td>282,248</td>
        <td>108,469</td>
        <td>390,717</td>
      </tr>
      <tr>
        <th scope="row">Jul-03</th>
        <td>231,118</td>
        <td>80,972</td>
        <td>312,090</td>
        <td>118,596</td>
        <td>430,686</td>
      </tr>
      <tr>
        <th scope="row">Jun-03</th>
        <td>204,344</td>
        <td>70,992</td>
        <td>275,336</td>
        <td>111,180</td>
        <td>386,516</td>
      </tr>
      <tr>
        <th scope="row">May-03</th>
        <td>196,565</td>
        <td>76,606</td>
        <td>273,171</td>
        <td>115,215</td>
        <td>388,386</td>
      </tr>
      <tr>
        <th scope="row">Apr-03</th>
        <td>200,476</td>
        <td>75,295</td>
        <td>275,771</td>
        <td>125,015</td>
        <td>400,786</td>
      </tr>
      <tr>
        <th scope="row">Mar-03</th>
        <td>179,800</td>
        <td>82,797</td>
        <td>262,597</td>
        <td>93,935</td>
        <td>356,532</td>
      </tr>
      <tr>
        <th scope="row">Feb-03</th>
        <td>158,863</td>
        <td>67,645</td>
        <td>226,508</td>
        <td>96,098</td>
        <td>322,606</td>
      </tr>
      <tr>
        <th scope="row">Jan-03</th>
        <td>168,186</td>
        <td>57,626</td>
        <td>225,812</td>
        <td>108,534</td>
        <td>334,346</td>
      </tr>
      <tr>
        <th scope="row">Dec-02</th>
        <td>186,243</td>
        <td>64,262</td>
        <td>250,505</td>
        <td>111,457</td>
        <td>361,962</td>
      </tr>
      <tr>
        <th scope="row">Nov-02</th>
        <td>179,463</td>
        <td>64,946</td>
        <td>244,409</td>
        <td>90,740</td>
        <td>335,149</td>
      </tr>
      <tr>
        <th scope="row">Oct-02</th>
        <td>163,776</td>
        <td>40,608</td>
        <td>204,384</td>
        <td>96,934</td>
        <td>301,318</td>
      </tr>
      <tr>
        <th scope="row">Sep-02</th>
        <td>188,874</td>
        <td>53,924</td>
        <td>242,798</td>
        <td>97,541</td>
        <td>340,339</td>
      </tr>
      <tr>
        <th scope="row">Aug-02</th>
        <td>234,592</td>
        <td>71,086</td>
        <td>305,678</td>
        <td>121,558</td>
        <td>427,236</td>
      </tr>
      <tr>
        <th scope="row">Jul-02</th>
        <td>249,618</td>
        <td>70,500</td>
        <td>320,118</td>
        <td>131,799</td>
        <td>451,917</td>
      </tr>
      <tr>
        <th scope="row">Jun-02</th>
        <td>236,112</td>
        <td>75,814</td>
        <td>311,926</td>
        <td>107,051</td>
        <td>418,977</td>
      </tr>
      <tr>
        <th scope="row">May-02</th>
        <td>231,382</td>
        <td>84,062</td>
        <td>315,444</td>
        <td>112,589</td>
        <td>428,033</td>
      </tr>
      <tr>
        <th scope="row">Apr-02</th>
        <td>227,953</td>
        <td>87,960</td>
        <td>315,913</td>
        <td>94,287</td>
        <td>410,200</td>
      </tr>
      <tr>
        <th scope="row">Mar-02</th>
        <td>171,874</td>
        <td>87,845</td>
        <td>259,719</td>
        <td>83,887</td>
        <td>343,606</td>
      </tr>
      <tr>
        <th scope="row">Feb-02</th>
        <td>202,276</td>
        <td>83,333</td>
        <td>285,609</td>
        <td>77,374</td>
        <td>362,983</td>
      </tr>
      <tr>
        <th scope="row">Jan-02</th>
        <td>180,528</td>
        <td>70,944</td>
        <td>251,472</td>
        <td>93,173</td>
        <td>344,645</td>
      </tr>
      <tr>
        <th scope="row">Dec-01</th>
        <td>191,584</td>
        <td>83,660</td>
        <td>275,244</td>
        <td>84,299</td>
        <td>359,543</td>
      </tr>
      <tr>
        <th scope="row">Nov-01</th>
        <td>193,861</td>
        <td>75,879</td>
        <td>269,740</td>
        <td>102,345</td>
        <td>372,085</td>
      </tr>
      <tr>
        <th scope="row">Oct-01</th>
        <td>238,185</td>
        <td>82,255</td>
        <td>320,440</td>
        <td>114,428</td>
        <td>434,868</td>
      </tr>
      <tr>
        <th scope="row">Sep-01</th>
        <td>220,204</td>
        <td>76,724</td>
        <td>296,928</td>
        <td>91,451</td>
        <td>388,379</td>
      </tr>
      <tr>
        <th scope="row">Aug-01</th>
        <td>221,492</td>
        <td>83,301</td>
        <td>304,793</td>
        <td>101,069</td>
        <td>405,862</td>
      </tr>
      <tr>
        <th scope="row">Jul-01</th>
        <td>208,179</td>
        <td>77,956</td>
        <td>286,135</td>
        <td>92,897</td>
        <td>379,032</td>
      </tr>
      <tr>
        <th scope="row">Jun-01</th>
        <td>202,988</td>
        <td>78,395</td>
        <td>281,383</td>
        <td>81,275</td>
        <td>362,658</td>
      </tr>
      <tr>
        <th scope="row">May-01</th>
        <td>196,120</td>
        <td>81,108</td>
        <td>277,228</td>
        <td>90,344</td>
        <td>367,572</td>
      </tr>
      <tr>
        <th scope="row">Apr-01</th>
        <td>202,575</td>
        <td>76,624</td>
        <td>279,199</td>
        <td>87,329</td>
        <td>366,528</td>
      </tr>
      <tr>
        <th scope="row">Mar-01</th>
        <td>195,605</td>
        <td>84,349</td>
        <td>279,954</td>
        <td>80,325</td>
        <td>360,279</td>
      </tr>
      <tr>
        <th scope="row">Feb-01</th>
        <td>155,148</td>
        <td>75,520</td>
        <td>230,668</td>
        <td>81,044</td>
        <td>311,712</td>
      </tr>
      <tr>
        <th scope="row">Jan-01</th>
        <td>194,738</td>
        <td>77,073</td>
        <td>271,811</td>
        <td>82,622</td>
        <td>354,433</td>
      </tr>
      <tr>
        <th scope="row">Dec-00</th>
        <td>180,631</td>
        <td>79,351</td>
        <td>259,982</td>
        <td>91,408</td>
        <td>351,390</td>
      </tr>
      <tr>
        <th scope="row">Nov-00</th>
        <td>213,065</td>
        <td>90,756</td>
        <td>303,821</td>
        <td>107,076</td>
        <td>410,897</td>
      </tr>
      <tr>
        <th scope="row">Oct-00</th>
        <td>235,511</td>
        <td>88,541</td>
        <td>324,052</td>
        <td>108,880</td>
        <td>432,932</td>
      </tr>
      <tr>
        <th scope="row">Sep-00</th>
        <td>208,880</td>
        <td>80,875</td>
        <td>289,755</td>
        <td>97,838</td>
        <td>387,593</td>
      </tr>
      <tr>
        <th scope="row">Aug-00</th>
        <td>236,969</td>
        <td>86,012</td>
        <td>322,981</td>
        <td>111,760</td>
        <td>434,741</td>
      </tr>
      <tr>
        <th scope="row">Jul-00</th>
        <td>224,865</td>
        <td>86,874</td>
        <td>311,739</td>
        <td>98,240</td>
        <td>409,979</td>
      </tr>
      <tr>
        <th scope="row">Jun-00</th>
        <td>203,004</td>
        <td>83,305</td>
        <td>286,309</td>
        <td>88,586</td>
        <td>374,895</td>
      </tr>
      <tr>
        <th scope="row">May-00</th>
        <td>218,016</td>
        <td>97,770</td>
        <td>315,786</td>
        <td>90,986</td>
        <td>406,772</td>
      </tr>
      <tr>
        <th scope="row">Apr-00</th>
        <td>200,724</td>
        <td>91,014</td>
        <td>291,738</td>
        <td>74,727</td>
        <td>366,465</td>
      </tr>
      <tr>
        <th scope="row">Mar-00</th>
        <td>178,489</td>
        <td>97,871</td>
        <td>276,360</td>
        <td>74,404</td>
        <td>350,764</td>
      </tr>
      <tr>
        <th scope="row">Feb-00</th>
        <td>177,362</td>
        <td>81,893</td>
        <td>259,255</td>
        <td>75,961</td>
        <td>335,216</td>
      </tr>
      <tr>
        <th scope="row">Jan-00</th>
        <td>178,672</td>
        <td>80,091</td>
        <td>258,763</td>
        <td>80,380</td>
        <td>339,143</td>
      </tr>
      <tr>
        <th scope="row">Dec-99</th>
        <td>185,572</td>
        <td>86,697</td>
        <td>272,269</td>
        <td>92,971</td>
        <td>365,240</td>
      </tr>
      <tr>
        <th scope="row">Nov-99</th>
        <td>209,444</td>
        <td>91,201</td>
        <td>300,645</td>
        <td>96,656</td>
        <td>397,301</td>
      </tr>
      <tr>
        <th scope="row">Oct-99</th>
        <td>204,242</td>
        <td>84,349</td>
        <td>288,591</td>
        <td>107,829</td>
        <td>396,420</td>
      </tr>
      <tr>
        <th scope="row">Sep-99</th>
        <td>219,080</td>
        <td>82,308</td>
        <td>301,388</td>
        <td>101,322</td>
        <td>402,710</td>
      </tr>
      <tr>
        <th scope="row">Aug-99</th>
        <td>214,709</td>
        <td>80,581</td>
        <td>295,290</td>
        <td>104,015</td>
        <td>399,305</td>
      </tr>
      <tr>
        <th scope="row">Jul-99</th>
        <td>188,586</td>
        <td>74,155</td>
        <td>262,741</td>
        <td>85,220</td>
        <td>347,961</td>
      </tr>
      <tr>
        <th scope="row">Jun-99</th>
        <td>194,410</td>
        <td>78,962</td>
        <td>273,372</td>
        <td>100,603</td>
        <td>373,975</td>
      </tr>
      <tr>
        <th scope="row">May-99</th>
        <td>205,640</td>
        <td>87,553</td>
        <td>293,193</td>
        <td>101,274</td>
        <td>394,467</td>
      </tr>
      <tr>
        <th scope="row">Apr-99</th>
        <td>181,575</td>
        <td>77,040</td>
        <td>258,615</td>
        <td>81,071</td>
        <td>339,686</td>
      </tr>
      <tr>
        <th scope="row">Mar-99</th>
        <td>171,913</td>
        <td>88,699</td>
        <td>260,612</td>
        <td>87,362</td>
        <td>347,974</td>
      </tr>
      <tr>
        <th scope="row">Feb-99</th>
        <td>172,482</td>
        <td>80,000</td>
        <td>252,482</td>
        <td>71,647</td>
        <td>324,129</td>
      </tr>
      <tr>
        <th scope="row">Jan-99</th>
        <td>169,397</td>
        <td>77,676</td>
        <td>247,073</td>
        <td>72,239</td>
        <td>319,312</td>
      </tr>
      <tr>
        <th scope="row">Dec-98</th>
        <td>166,532</td>
        <td>87,527</td>
        <td>254,059</td>
        <td>82,589</td>
        <td>336,648</td>
      </tr>
      <tr>
        <th scope="row">Nov-98</th>
        <td>192,661</td>
        <td>84,291</td>
        <td>276,952</td>
        <td>91,189</td>
        <td>368,141</td>
      </tr>
      <tr>
        <th scope="row">Oct-98</th>
        <td>180,719</td>
        <td>77,171</td>
        <td>257,890</td>
        <td>89,572</td>
        <td>347,462</td>
      </tr>
      <tr>
        <th scope="row">Sep-98</th>
        <td>198,945</td>
        <td>73,784</td>
        <td>272,729</td>
        <td>100,017</td>
        <td>372,746</td>
      </tr>
      <tr>
        <th scope="row">Aug-98</th>
        <td>195,413</td>
        <td>77,416</td>
        <td>272,829</td>
        <td>99,786</td>
        <td>372,615</td>
      </tr>
      <tr>
        <th scope="row">Jul-98</th>
        <td>199,138</td>
        <td>80,950</td>
        <td>280,088</td>
        <td>98,626</td>
        <td>378,714</td>
      </tr>
      <tr>
        <th scope="row">Jun-98</th>
        <td>183,019</td>
        <td>82,615</td>
        <td>265,634</td>
        <td>89,402</td>
        <td>355,036</td>
      </tr>
      <tr>
        <th scope="row">May-98</th>
        <td>163,640</td>
        <td>84,381</td>
        <td>248,021</td>
        <td>83,598</td>
        <td>331,619</td>
      </tr>
      <tr>
        <th scope="row">Apr-98</th>
        <td>172,247</td>
        <td>87,271</td>
        <td>259,518</td>
        <td>77,406</td>
        <td>336,924</td>
      </tr>
      <tr>
        <th scope="row">Mar-98</th>
        <td>167,712</td>
        <td>88,518</td>
        <td>256,230</td>
        <td>77,104</td>
        <td>333,334</td>
      </tr>
      <tr>
        <th scope="row">Feb-98</th>
        <td>132,117</td>
        <td>77,303</td>
        <td>209,420</td>
        <td>66,756</td>
        <td>276,176</td>
      </tr>
      <tr>
        <th scope="row">Jan-98</th>
        <td>144,758</td>
        <td>72,420</td>
        <td>217,178</td>
        <td>71,096</td>
        <td>288,274</td>
      </tr>
      <tr>
        <th scope="row">Dec-97</th>
        <td>145,840</td>
        <td>91,138</td>
        <td>236,978</td>
        <td>64,014</td>
        <td>300,992</td>
      </tr>
      <tr>
        <th scope="row">Nov-97</th>
        <td>146,354</td>
        <td>96,568</td>
        <td>242,922</td>
        <td>68,070</td>
        <td>310,992</td>
      </tr>
      <tr>
        <th scope="row">Oct-97</th>
        <td>169,995</td>
        <td>87,101</td>
        <td>257,096</td>
        <td>57,860</td>
        <td>314,956</td>
      </tr>
      <tr>
        <th scope="row">Sep-97</th>
        <td>183,914</td>
        <td>90,761</td>
        <td>274,675</td>
        <td>57,795</td>
        <td>332,470</td>
      </tr>
      <tr>
        <th scope="row">Aug-97</th>
        <td>160,215</td>
        <td>87,518</td>
        <td>247,733</td>
        <td>49,775</td>
        <td>297,508</td>
      </tr>
      <tr>
        <th scope="row">Jul-97</th>
        <td>169,964</td>
        <td>96,244</td>
        <td>266,208</td>
        <td>48,792</td>
        <td>315,000</td>
      </tr>
      <tr>
        <th scope="row">Jun-97</th>
        <td>165,763</td>
        <td>100,262</td>
        <td>266,025</td>
        <td>43,604</td>
        <td>309,629</td>
      </tr>
      <tr>
        <th scope="row">May-97</th>
        <td>143,330</td>
        <td>91,734</td>
        <td>235,064</td>
        <td>42,953</td>
        <td>278,017</td>
      </tr>
      <tr>
        <th scope="row">Apr-97</th>
        <td>146,110</td>
        <td>92,893</td>
        <td>239,003</td>
        <td>37,931</td>
        <td>276,934</td>
      </tr>
      <tr>
        <th scope="row">Mar-97</th>
        <td>125,040</td>
        <td>106,556</td>
        <td>231,596</td>
        <td>42,326</td>
        <td>273,922</td>
      </tr>
      <tr>
        <th scope="row">Feb-97</th>
        <td>126,804</td>
        <td>87,586</td>
        <td>214,390</td>
        <td>35,884</td>
        <td>250,274</td>
      </tr>
      <tr>
        <th scope="row">Jan-97</th>
        <td>123,456</td>
        <td>78,965</td>
        <td>202,421</td>
        <td>41,484</td>
        <td>243,905</td>
      </tr>
      <tr>
        <th scope="row">Dec-96</th>
        <td>133,087</td>
        <td>100,557</td>
        <td>233,644</td>
        <td>37,737</td>
        <td>271,381</td>
      </tr>
      <tr>
        <th scope="row">Nov-96</th>
        <td>125,971</td>
        <td>86,338</td>
        <td>212,309</td>
        <td>39,910</td>
        <td>252,219</td>
      </tr>
      <tr>
        <th scope="row">Oct-96</th>
        <td>150,796</td>
        <td>89,676</td>
        <td>240,472</td>
        <td>44,390</td>
        <td>284,862</td>
      </tr>
      <tr>
        <th scope="row">Sep-96</th>
        <td>147,405</td>
        <td>82,123</td>
        <td>229,528</td>
        <td>48,001</td>
        <td>277,529</td>
      </tr>
      <tr>
        <th scope="row">Aug-96</th>
        <td>139,554</td>
        <td>82,965</td>
        <td>222,519</td>
        <td>46,353</td>
        <td>268,872</td>
      </tr>
      <tr>
        <th scope="row">Jul-96</th>
        <td>146,839</td>
        <td>83,146</td>
        <td>229,985</td>
        <td>36,962</td>
        <td>266,947</td>
      </tr>
      <tr>
        <th scope="row">Jun-96</th>
        <td>132,419</td>
        <td>89,889</td>
        <td>222,308</td>
        <td>40,016</td>
        <td>262,324</td>
      </tr>
      <tr>
        <th scope="row">May-96</th>
        <td>120,002</td>
        <td>87,242</td>
        <td>207,244</td>
        <td>32,777</td>
        <td>240,021</td>
      </tr>
      <tr>
        <th scope="row">Apr-96</th>
        <td>127,583</td>
        <td>100,782</td>
        <td>228,365</td>
        <td>29,775</td>
        <td>258,140</td>
      </tr>
      <tr>
        <th scope="row">Mar-96</th>
        <td>101,338</td>
        <td>101,382</td>
        <td>202,720</td>
        <td>27,480</td>
        <td>230,200</td>
      </tr>
      <tr>
        <th scope="row">Feb-96</th>
        <td>113,349</td>
        <td>90,233</td>
        <td>203,582</td>
        <td>23,322</td>
        <td>226,904</td>
      </tr>
      <tr>
        <th scope="row">Jan-96</th>
        <td>109,235</td>
        <td>87,554</td>
        <td>196,789</td>
        <td>31,147</td>
        <td>227,936</td>
      </tr>
      <tr>
        <th scope="row">Dec-95</th>
        <td>104,146</td>
        <td>92,793</td>
        <td>196,939</td>
        <td>36,927</td>
        <td>233,866</td>
      </tr>
      <tr>
        <th scope="row">Nov-95</th>
        <td>109,200</td>
        <td>89,899</td>
        <td>199,099</td>
        <td>44,000</td>
        <td>243,099</td>
      </tr>
      <tr>
        <th scope="row">Oct-95</th>
        <td>134,836</td>
        <td>92,769</td>
        <td>227,605</td>
        <td>43,982</td>
        <td>271,587</td>
      </tr>
      <tr>
        <th scope="row">Sep-95</th>
        <td>114,541</td>
        <td>81,469</td>
        <td>196,010</td>
        <td>46,443</td>
        <td>242,453</td>
      </tr>
      <tr>
        <th scope="row">Aug-95</th>
        <td>127,944</td>
        <td>89,354</td>
        <td>217,298</td>
        <td>46,836</td>
        <td>264,134</td>
      </tr>
      <tr>
        <th scope="row">Jul-95</th>
        <td>127,008</td>
        <td>88,173</td>
        <td>215,181</td>
        <td>40,852</td>
        <td>256,033</td>
      </tr>
      <tr>
        <th scope="row">Jun-95</th>
        <td>113,419</td>
        <td>83,817</td>
        <td>197,236</td>
        <td>30,462</td>
        <td>227,698</td>
      </tr>
      <tr>
        <th scope="row">May-95</th>
        <td>113,688</td>
        <td>91,226</td>
        <td>204,914</td>
        <td>35,462</td>
        <td>240,376</td>
      </tr>
      <tr>
        <th scope="row">Apr-95</th>
        <td>110,518</td>
        <td>86,879</td>
        <td>197,397</td>
        <td>29,534</td>
        <td>226,931</td>
      </tr>
      <tr>
        <th scope="row">Mar-95</th>
        <td>94,125</td>
        <td>83,964</td>
        <td>178,089</td>
        <td>28,349</td>
        <td>206,438</td>
      </tr>
      <tr>
        <th scope="row">Feb-95</th>
        <td>99,121</td>
        <td>81,879</td>
        <td>181,000</td>
        <td>35,102</td>
        <td>216,102</td>
      </tr>
      <tr>
        <th scope="row">Jan-95</th>
        <td>104,774</td>
        <td>74,091</td>
        <td>178,865</td>
        <td>36,030</td>
        <td>214,895</td>
      </tr>
    </tbody>
  </table>
</div>


          </article>
        </div>
      </Layout>
    );
  }
}

export default ComponentsTables;